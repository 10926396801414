import { changeURL, loadPageContent } from './_page_tools.js';
import { loc } from './_location.js';
import { load } from './_load.js';
class ModalPage{
	constructor(){
		this.current 	= 0;
		this.max 		= 0;
		console.log(modalContent.data);
		this.checkURL();
	}
	checkURL(){
		let $this 		= this;
		let locData 	= loc.all();
		let params 		= locData.params;
		let placeNum 	= parseInt( params.place );
		//console.log(placeNum, locData, params);
		if( params.place ){
			$this.makeHTML( placeNum );
		}else{
			$this.close();
		}
	}
	makeHTML(num){
		let $this = this;
		let data = modalContent.data[num];
		console.log(data);
		let $modal = document.querySelector("#modal");
		let $kv 	= $modal.querySelector("figure img");
		$kv.src 	= "/assets/images/contents/" + data.image_name.replace(".png", ".webp")
		
		let $ttlEn = $modal.querySelector(".c-en h3");
		let $txtEn = $modal.querySelector(".c-en p");
		$ttlEn.innerHTML = data.en_title + "<br>" + data.en_sub_title;
		$txtEn.innerHTML = data.en_description;
		
		let $ttlJa = $modal.querySelector(".c-ja h3");
		let $txtJa = $modal.querySelector(".c-ja p");
		$ttlJa.innerHTML = data.jp_title + data.jp_sub_title;
		$txtJa.innerHTML = data.jp_description;
		setTimeout(function(){
			$this.open();
		}, 50);
		let $btnClose = document.querySelectorAll("[data-close-modal]");
		$btnClose.forEach( ($n, index) => {
			$n.removeEventListener("click", $this.removeParam, false);
			$n.addEventListener("click", $this.removeParam, false);
		});
	}
	removeParam(){
		changeURL("/");
	}
	open(){
		let $html = document.documentElement;
		if( !$html.classList.contains("show_modal") ){
			$html.classList.add("show_modal");
		}
	}
	close(){
		let $html = document.documentElement;
		if( $html.classList.contains("show_modal") ){
			$html.classList.remove("show_modal");
		}
	}
}
export let modalContent = {
	page: null,
	data: [],
	jsonURL: "/assets/json/content.json",
	init: function(){
		load.getJson(modalContent.jsonURL).then( (res) => {
			modalContent.data = res;
			modalContent.makeList();
			modalContent.page = new ModalPage();
			// popstateイベントリスナーの設定
			window.addEventListener('popstate', function(event) {
				const urlChangeEvent = new Event('urlchange');
				urlChangeEvent.state = event.state;
				window.dispatchEvent(urlChangeEvent);
			});
			
			// カスタムイベントリスナーの設定
			window.addEventListener('urlchange', function(event) {
				//console.log("urlChange:", window.location.href, event.state);
				// 新しい状態に応じた処理を行う関数を呼び出し
				modalContent.page.checkURL(event.state);
			});
			modalContent.nav();
		});
	},
	nav: function(){
		let $navs = document.querySelectorAll("[data-place]");
		$navs.forEach( ($n, index) => {
			$n.removeEventListener("click", modalContent.getURL, false);
			$n.addEventListener("click", modalContent.getURL, false);
		});
	},
	getURL: function(e){
		e.preventDefault();
		let path = this.getAttribute("href");
		console.log(path);
		changeURL(path);
	},
	makeList: function(){
		let HTML = "";
		for(let i = 0; i < modalContent.data.length; i++){
			let d = modalContent.data[i];
			let thumbnail = d.thumbnail.replace(".png", ".webp");
			HTML += `<li class="p p${i}">
						<a href="?place=${i}" data-place>
							<figure>
								<img src="/assets/images/top/thumb/${thumbnail}" alt="${d.en_title}">
							</figure>
							<span class="icon icon_go">
								<svg role="img"><title>Open</title><use x="0px" y="0px" xlink:href="#icon_open"></use></svg>
							</span>
						</a>
					</li>`;
		}
		document.querySelector(".c-places ul").innerHTML = HTML;
	}
}