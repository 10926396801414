export let changeURL = (url) => {
	return new Promise((resolve, reject) => {
		let state = { page_id: location.pathname };
		history.pushState(state, "", url);
		resolve();
	});
}

const originalPushState 	= history.pushState;
const originalReplaceState 	= history.replaceState;

// pushStateとreplaceStateをラップ
history.pushState = function(state, title, url) {
	const result = originalPushState.apply(this, arguments);
	const event = new Event('urlchange');
	event.state = state;
	window.dispatchEvent(event);
	return result;
};

history.replaceState = function(state, title, url) {
	const result = originalReplaceState.apply(this, arguments);
	const event = new Event('urlchange');
	event.state = state;
	window.dispatchEvent(event);
	return result;
};

export let loadPageContent = ( url ) => {
	let $body = document.querySelector("body");
	return new Promise( (resolve, reject) => {
		fetch(url).then(response => response.text()).then(html => {
			const tempDiv 		= document.createElement('div');
			tempDiv.innerHTML 	= html;
			changeBodyData(html);
			const title 		= tempDiv.querySelector("title").innerText;
			document.title 		= title;
			const targetElement = tempDiv.querySelector(`[data-load-wrap] [data-load-inner]`);
			//console.log(targetElement);
			document.querySelector("[data-load-wrap] [data-load-inner]").innerHTML = targetElement.innerHTML;
			
			let imgs = tempDiv.querySelectorAll("img");
			let imgArry = [];
			imgs.forEach( ($img, index) => {
				let src = $img.getAttribute("src");
				if( src && src != "" ){
					imgArry.push( src );
				}
			});
			if(imgArry.length > 0){
				pageLoading.startLoad( imgArry ).then( ()=> {
					$body.classList.remove("change_page");
					resolve();
				});
			}else{
				$body.classList.remove("change_page");
				resolve();
			}
			
		})
		.catch(error => console.error('Error:', error));
	});
}