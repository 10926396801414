import { preload } from './lib/_preload.js';
import { loc } from './lib/_location.js';
import { common } from './_common.js';
import { modalContent } from './lib/_modal.js';

export class Framework {
	constructor(){
		this.pageinfo = loc.all();
		this.nextpage = "";
		this.checkURL();
		console.log(this.pageinfo);
	}
	
	checkURL(){
		this.changeContent();
	}
		
	changeContent(){
		this.afterChangePage();
	}
	
	afterChangePage(){
		common.init();
		modalContent.init();
		common.resize();
		common.nav();
	}
	
}