export const load = {
	getJson: function(url){
		console.log("getJson:", url);
		return fetch(url).then( function(res){
			return res.json();
		}).then(function(data){
			return data;
		}).catch(function(error){
			return error
		});
	},
	postJson: function(url, param){
		const options = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			},
			body: param
		}
		return fetch(url, options).then( function(res){
			return res.json();
		}).then(function(data){
			return data;
		}).catch(function(error){
			return error
		});
	}
}