import { shareSNS } from './lib/_share.js';
import { Scroll } from './lib/_scroll.js';

export var common = {
	scrl: "",
	beforeW: 0,
	init: ()=>{
		common.start();
	},
	start: function(){
		let resizeFlag;
		let resizeTimer = null;
		let $body = document.getElementsByTagName('body')[0];
		
		common.resize();
		common.nav();
		shareSNS.nav();
		common.scrl = new Scroll();
		
		/* window resize
		----------------------------- */
		var resizeCallback = function() {
			common.resize();
			resizeTimer = null;
		};
		window.addEventListener("resize", function(){
			if( !$body.classList.contains("resize") ){
				$body.classList.add("resize");
			}
			if(resizeTimer) {
				clearTimeout(resizeTimer);
			}
			resizeTimer = setTimeout(resizeCallback, 300);
		}, false);
	},
	nav: ()=>{
		let $btnMenu 	= document.querySelector("[data-menu]");
		let $btnClose 	= document.querySelector("#bg_nav");
		if($btnMenu){
			//single handlers
			const eventListeners = [
				{ element: $btnMenu, type: "click", handler: common.toggleMenu},
				{ element: $btnClose, type: "click", handler: common.toggleMenu}
			]
			eventListeners.forEach( ( el ) => {
				if(el.element){
					el.element.removeEventListener(el.type, el.handler, false);
					el.element.addEventListener(el.type, el.handler, false);
				}
			});
		}
		let $btnScrolls = document.querySelectorAll("[data-scroll]");
		const eventListenersMultiple = [
			{ elements: $btnScrolls, type: "click", handler: { name: this, handleEvent: common.scrollToPos } }
		];
		eventListenersMultiple.forEach( ( el ) => {
			el.elements.forEach( ( element, index )=> {
				element.removeEventListener(el.type, el.handler, false);
				element.addEventListener(el.type, el.handler, false);
			});
		});
	},
	toggleMenu: function(){
		let $html = document.documentElement;
		if( !$html.classList.contains("show_menu") ){
			$html.classList.add("show_menu");
		}else{
			$html.classList.remove("show_menu");
		}
	},
	resize: ()=>{
		var winW 	= window.innerWidth;
		var winH 	= window.innerHeight;
		let $body 	= document.getElementsByTagName('body')[0];
		
		addressbarH();
		
		common.getScrollBar();
		/*
		if(common.scrl){
			common.scrl.checkBlock();
			common.scrl.checkScroll();
		}
		*/
		setTimeout(function(){
			if( $body.classList.contains("resize") ){
				$body.classList.remove("resize");
			}
			/*
			if(common.scrl){
				common.scrl.checkBlock();
				common.scrl.checkScroll();
			}
			*/
		},300);
		function addressbarH(){
			if(common.beforeW != winW){
				// 100vh、アドレスバーが出てる時に対応
				let vh = window.innerHeight * 0.01;
				document.documentElement.style.setProperty("--vh", `${vh}px`);
			}
		}
		common.beforeW = winW;
	},
	getScrollBar: function(){
		const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
		document.documentElement.style.setProperty('--scrollbar', `${scrollbarWidth}px`);
	},
	removeMultipleClass: function(dom, className){
		const target = document.querySelector(dom);
		const targetClassList = target.className;
		const myRegExp = new RegExp("/\b" + className + "\S+/", 'g');
		const myMatched = targetClassList.match(myRegExp) || [];
		for(let n = 0; n < myMatched.length; n++) {
		  target.classList.remove(myMatched[n]);
		}
	},
	scrollToPos: function(e){
		e.preventDefault();
		let $html 	= document.documentElement;
		let id 		= e.currentTarget.getAttribute("href");
		let $dom 	= document.querySelector(id);
		let posY 	= Math.floor( $dom.getBoundingClientRect().top + window.pageYOffset );
		
		window.scrollTo({
			top: posY,
			behavior: "smooth"
		});
		if( $html.classList.contains("show_menu") ){
			$html.classList.remove("show_menu");
		}
	}
}
