export let shareSNS = {
	nav: function(){
		let shareBtn = document.querySelectorAll(`[data-share]`);
		shareBtn.forEach( ($btn) => {
			$btn.removeEventListener("click", shareSNS.share, false);
			$btn.addEventListener("click", shareSNS.share, false);
		});
	},
	share: function(e){
		e.preventDefault();
		let type 	= this.getAttribute("data-share");
		let url 	= location.href;
		let $this 	= this;
		if (type === "x" ){ //twitter
			var txt = $this.getAttribute("data-text");
			//&via=[ツイート内に含むユーザ名]&related=[ツイート後に表示されるユーザー]&hashtags=[ハッシュタグ]
			window.open(
				`https://twitter.com/share?url=${url}&text=${encodeURIComponent(txt)}`,
				'twittersharedialog', 'width=626,height=436');
		}else if( type === "fb" ){ //facebook
			window.open(
				'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url),
				'facebooksharedialog', 'width=626,height=436');
		}else{ //LINE
			var txt = $this.getAttribute("data-text") + "\n" + url;
			window.open(
				'https://line.me/R/msg/text/?' + encodeURIComponent(txt),
				'linesharedialog', 'width=626,height=436');
		}
	}
}